import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Index1 from './Pages/Index1';



import About from './Pages/About';
import Ourservices from './Pages/Ourservices';
import Faq from './Pages/Faq';
import Booking from './Pages/Booking';
import Error from './Pages/Error';
import Calendar from './Pages/Calendar';
import Team from './Pages/Team';


import Ourmenustyle2 from './Pages/Ourmenustyle2';
import Ourmenustyle3 from './Pages/Ourmenustyle3';



import Bloggrid2 from './Pages/Bloggrid2';


import Contact from './Pages/Contact';
import Contact2 from './Pages/Contact2';



class Markup extends Component{
	
	render(){
		return(
			<BrowserRouter basename='/react'>
				<Switch>
					<Route path='/' exact component={Index1} />
					
					<Route path='/about-1' exact component ={About} />
					<Route path='/our-services' exact component ={Ourservices} />
					<Route path='/faq' exact component ={Faq} />
					<Route path='/booking' exact component ={Booking} />
					<Route path='/error-404' exact component ={Error} />
					<Route path='/calendar' exact component ={Calendar} />
					<Route path='/team' exact component ={Team} />
					
					<Route path='/our-menu-2' exact component ={Ourmenustyle2} />
					<Route path='/our-menu-3' exact component ={Ourmenustyle3} />
					
					<Route path='/blog-grid-2' exact component ={Bloggrid2} />
					
					<Route path='/contact-1' exact component ={Contact} />
					<Route path='/contact-2' exact component ={Contact2} />
				
					
					
				</Switch>
			</ BrowserRouter>
		)
		
	}
}

export default Markup;