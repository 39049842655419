import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

class Accord extends Component{
	render(){		
		return(
			<div className="dlab-accordion faq-style1">
				<Accordion defaultActiveKey="0">		
					<Accordion.Toggle className="panel" as={'div'}  eventKey="0">
						<div className="acod-head">
							<h6 className="acod-title"> 
								<Link to = {''} data-toggle="collapse" data-target="#collapse1" aria-expanded="true"> 
								<i className="fa fa-cart-plus"></i> Where you find us ? </Link> 
							</h6>
						</div>
						<Accordion.Collapse eventKey="0">
							<div id="collapse1" className="acod-body collapse show" data-parent="#accordion001">
								<div className="acod-content">
									<img src={require('./../../images/map.png')}  alt="" />
									<ul className="list-check mb-0 primary">
										<li><Link to = {''}>Ahmedabad</Link></li>
										<li><Link to = {''}>Mehsana</Link></li>
										<li><Link to = {''}>Rajkot</Link></li>
										<li><Link to = {''}>Patan</Link></li>
										<li><Link to = {''}>Gandhinagar</Link></li>
										<li><Link to = {''}>Surat</Link></li>
										<li><Link to = {''}>Vadodara</Link></li>
										<li><Link to = {''}>Jamnagar</Link></li>
										<li><Link to = {''}>Bhavnagar</Link></li>
										<li><Link to = {''}>Bharuch</Link></li>
										<li><Link to = {''}>Anand</Link></li>
										<li><Link to = {''}>Morbi</Link></li>
									</ul>
								</div>
							</div>	
						</Accordion.Collapse>
					</Accordion.Toggle>	
					<Accordion.Toggle as={'div'} className="panel" eventKey="1">
						<div className="acod-head">
							<h6 className="acod-title"> 
								<Link to = {''} data-toggle="collapse" data-target="#collapse2" className="collapsed" aria-expanded="false">
								<i className="fa fa-address-book"></i> Become Our Partners</Link> 
							</h6>
						</div>
					
						<Accordion.Collapse eventKey="1">
							<div id="collapse2" className="acod-body" data-parent="#accordion001">
								<div className="acod-content">
									<img src={require('./../../images/about/handshake.png')} className="alignleft radius-md" alt="" />
									<p>
										If you're interested in exploring a partnership for a fast-food franchise, we welcome the opportunity to discuss potential collaboration. Our franchise program offers a proven business model with a strong brand presence, enticing menu offerings, and comprehensive support for our partners. We value individuals or entities who share our commitment to delivering quality products and exceptional customer experiences. To initiate the partnership process, please reach out to our corporate office, where our dedicated team will guide you through the application process, provide detailed information on franchise terms, and address any inquiries you may have. We believe in fostering mutually beneficial relationships and look forward to the possibility of working together to bring our beloved brand to new heights.</p>
								</div>
							</div>
						</Accordion.Collapse>
					</Accordion.Toggle>	
					<Accordion.Toggle className="panel" as={'div'} eventKey="2">
						<div className="acod-head">
							<h6 className="acod-title"> 
								<Link to = {''} data-toggle="collapse" data-target="#collapse3" className="collapsed" aria-expanded="false">
								<i className="fa fa-cc-discover"></i> Get Information</Link> 
							</h6>
						</div>
						<Accordion.Collapse eventKey="2">
							<div id="collapse2" className="acod-body" data-parent="#accordion001">
								<div className="acod-content">
									<p>Thank you for your interest in PDOT. We value your inquiries and feedback. Please feel free to reach out to us through the following channels:</p>
									<p> For general information and inquiries, you can contact our main office:Email: info@pdotfoods.com,Phone: +91-6355601213Address: Ahmedabad,Gujarat </p>
									<p>If you prefer face-to-face communication, you are welcome to visit our corporate office. Please schedule an appointment in advance. </p>
								</div>
							</div>
						</Accordion.Collapse>
					</Accordion.Toggle>	
				</Accordion>
			</div>	
		)
	}

}

export default Accord;