import React, { useState } from 'react';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';


const Popupss = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div class="section-full bg-white pizza-full-menu">
            <div tabs>

                <div className="bg-primary pizza-items">
					<div className="container">
						<ul className="nav nav-tabs pizza-items filters">
							<li className="nav-item item">
								<input type="radio"/>
							   <Link className= {classnames({ active: activeTab === '1' },'item-icon-box nav-link')  } 
									onClick={() => { toggle('1'); }}  >
									<i className="flaticon-bhaji"></i>
									<span>Bhaji Pav</span>
								</Link>
							</li>
							<li className="nav-item item">
								<input type="radio" />
								<Link className={classnames({ active: activeTab === '2' },'item-icon-box nav-link')}
									onClick={() => { toggle('2'); }}>
									<i className="flaticon-burger"></i>
									<span>Burger</span>
								</Link>
							</li>
							<li className="nav-item item">
								<input type="radio" />
								<Link className= {classnames({ active: activeTab === '3' },'item-icon-box nav-link')}
									onClick={() => { toggle('3'); }}>
									<i className="flaticon-french-fries"></i>
									<span>Puff</span>
								</Link>
							</li>
							<li className="nav-item item">
								<input type="radio" />
								<Link className={classnames({ active: activeTab === '4' },'item-icon-box nav-link')}
									onClick={() => { toggle('4'); }}>
									<i className="flaticon-cola"></i>
									<span>Momos</span>
								</Link>
							</li>
							<li className="nav-item item">
								<input type="radio" />
								<Link className={classnames({ active: activeTab === '5' },'item-icon-box nav-link')}
									onClick={() => { toggle('5'); }}>
									<i className="flaticon-hot-dog"></i>
									<span>Samosa</span>
								</Link>
							</li>							
							<li className="nav-item item">
								<input type="radio" />
								<Link className={classnames({ active: activeTab === '6' },'item-icon-box nav-link')}
									onClick={() => { toggle('6'); }}>
									<i className="flaticon-cookies"></i>
									<span>Paratha</span>
								</Link>
							</li>
							<li className="nav-item item">
								<input type="radio" />
								<Link className={classnames({ active: activeTab === '7' },'item-icon-box nav-link')}
									onClick={() => { toggle('7'); }}>
									<i className="flaticon-sandwich"></i>
									<span>Sandwich</span>
								</Link>
							</li>
						</ul>
					</div>
                </div>
            </div>
			
			<div className="content-inner">
				<div className="container-fluid">
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<div className="row tab-pane fade show active" >
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic1.png')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Esposito Margherita</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic2.png')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Candel of Italian</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic3.png')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Tandoori Paneer</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic4.jpg')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Mexican</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic5.jpg')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>American County</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic6.jpg')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Hot Passion</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic7.jpg')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Hawaian</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic8.jpg')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Chilly Garlic Special</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic9.jpg')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Spicy Peri Peri</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic1.png')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Garden Delight</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic11.png')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Cheese Lovers</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic12.png')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Jain Italian</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic1.png')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Cheese Garlic Bread</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic1.png')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Cheese Chilly Toast</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<img src={require('./../../images/product/pizza/pic12.png')} alt="" />
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>4" Mini Pizza with Cheese/without Cheese</span></h5>
										</div>
									</div>
								</div>
							</div>	
						</TabPane>
						<TabPane tabId="2">
							<div className="row " >
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/burger/Crispy_Alloo_Tikki_Burger.png')} alt="" /> </Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Aloo Tikki</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/burger/Classic_Veg_Burger.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Miz Veg.</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/burger/Maxicon_Mania_Burger.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Maxican</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/burger/Spicy_Schezwan_Veg_Burger.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Spicy Soya</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/burger/Spicy_Panner_Burger.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Spicy Paneer</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/burger/Melted_Cheeze_Chilli_Corn_Burger.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Cheese Corn</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/burger/Smoky_BBQ_Burger.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Supreme</span></h5>
										</div>
									</div>
								</div>
							</div>	
						</TabPane>
						<TabPane tabId="3">
							<div className="row " >
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item1.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Veggie Special Puff</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item2.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Pizza Puff</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item3.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Chinese Puff</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/puff/Peppy_Paneer_Malai_Puff.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Paneer Chataka Puff</span></h5>
										</div>
									</div>
								</div>
							</div>
						</TabPane>
						<TabPane tabId="4">
							<div className="row " >
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item5.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Veg Momos</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item3.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Paneer Momos</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item2.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Schezwan Momos</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item7.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Corn Cheese Momos</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item8.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Veg Cheese Momos</span></h5>
										</div>
									</div>
								</div>
							</div>
						</TabPane>
						<TabPane tabId="5">
							<div className="row " >
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item3.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Punjabi Samosa</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item4.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Paneer Chilly Samosa</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item6.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Cheese Chilly Samosa</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item8.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Cheese Corn Samosa</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item2.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Green Peas Samosa</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/item2.jpg')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Mix Veg Samosa</span></h5>
										</div>
									</div>
								</div>
							</div>
						</TabPane>
						
						<TabPane tabId="6">
							<div className="row " >
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Paratha/3 aloo paratha.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Allo Paratha</span></h5><div className="cart-btn"><Link to={'/shop-product-details'} className="btn btnhover radius-xl"><i className="ti-shopping-cart"></i> Add To Cart</Link>></div>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Paratha/3 aloo paratha.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Gobi Paratha</span></h5><div className="cart-btn"><Link to={'/shop-product-details'} className="btn btnhover radius-xl"><i className="ti-shopping-cart"></i> Add To Cart</Link>></div>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Paratha/3 aloo paratha.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Mix Veg Paratha</span></h5><div className="cart-btn"><Link to={'/shop-product-details'} className="btn btnhover radius-xl"><i className="ti-shopping-cart"></i> Add To Cart</Link>></div>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Paratha/4 paneer paratha.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Paneer Paratha</span></h5><div className="cart-btn"><Link to={'/shop-product-details'} className="btn btnhover radius-xl"><i className="ti-shopping-cart"></i> Add To Cart</Link>></div>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Paratha/4 paneer paratha.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Lachha Paratha</span></h5><div className="cart-btn"><Link to={'/shop-product-details'} className="btn btnhover radius-xl"><i className="ti-shopping-cart"></i> Add To Cart</Link>></div>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Paratha/4 paneer paratha.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Chilli Garlic Lachha Paratha</span></h5><div className="cart-btn"><Link to={'/shop-product-details'} className="btn btnhover radius-xl"><i className="ti-shopping-cart"></i> Add To Cart</Link>></div>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Paratha/4 paneer paratha.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Methi Thepla(Pack of Pieces)</span></h5><div className="cart-btn"><Link to={'/shop-product-details'} className="btn btnhover radius-xl"><i className="ti-shopping-cart"></i> Add To Cart</Link>></div>
										</div>
									</div>
								</div>
							</div>
						</TabPane>
						
						
						  <TabPane tabId="7">
							<div className="row ">
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Sandwhich/3_Lyr_Jumbo_Grilled_Sandwich.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Coleslaw-Jain</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Sandwhich/3_Lyr_Jumbo_Grilled_Sandwich.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Maxican Magic</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Sandwhich/Cheese_Chilli_Mayo_Sandwich.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Cheese Chilly Mayo</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Sandwhich/Zinping_Chinese_Sandwich.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Zing Ping Chinese</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Sandwhich/Tandoori_Paneer_Tadka.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>Tandoori Paneer</span></h5>
										</div>
									</div>
								</div>
								<div className="dz-col col m-b30">
									<div className="item-box shop-item style2">
										<div className="item-img">
											<Link><img src={require('./../../images/product/Sandwhich/American_Cheese_Corn.png')} alt="" /></Link>
										</div>
										<div className="item-info text-center">
											<h5 className="price text-primary"><span>American Corn Supreme</span></h5>
										</div>
									</div>
								</div>
							</div>
						</TabPane>	
					</TabContent>
				</div>	
			</div>	
        </div>
    );
}

export default Popupss;